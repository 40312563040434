import {
	AUTH_LOGIN,
	AUTH_LOGOUT,
	SET_DOCTOR,
	SET_USER,
	SET_OTP_KEY,
	SET_SLOTS,
} from "./types";

export const initialState = {
	user: null,
	doctor: null,
	slots: [],
	otp_key: null,
	isLoggedIn: false,
};

const reducer = (state, action) => {
	switch (action.type) {
		case SET_USER:
			return {
				...state,
				user: action.payload || null,
			};
		case SET_DOCTOR:
			return {
				...state,
				doctor: action.payload || null,
			};
		case SET_SLOTS:
			return {
				...state,
				slots: action.payload || [],
			};
		case SET_OTP_KEY:
			return {
				...state,
				otp_key: action.payload || null,
			};
		case AUTH_LOGIN:
			return {
				...state,
				isLoggedIn: true,
			};
		case AUTH_LOGOUT:
			return {
				...state,
				user: null,
				otp_key: null,
				isLoggedIn: false,
			};
		default:
			return state;
	}
};

export default reducer;
