import { Button, IconButton } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-horizontal-datepicker";
import { Skeleton } from "@material-ui/lab";
import { getSlotsByDate } from "../actions/doctorActions";
import { useAppContext } from "../context/StateProvider";
import styles from "../styles/AllSlots.module.css";
import divideSlots from "../utils/divideSlots";

function AllSlots({
	type,
	handleBookAppointment,
	setSelectedDate,
	selectedDate,
}) {
	const [{ doctor, slots }, dispatch] = useAppContext();
	const [loading, setLoading] = useState(true);

	const getSelectedDay = async (day) => {
		setSelectedDate(moment(day).format("YYYY-MM-DD"));
		setLoading(true);
		getSlotsByDate(
			type,
			doctor?.id,
			moment(day).format("YYYY-MM-DD"),
			dispatch
		).then(() => setLoading(false));
		// getSlotsByDate(
		// 	{ vendorId: doctor?.id, date: moment(day).format("YYYY-MM-DD") },
		// 	dispatch
		// ).then(() => setLoading(false));
	};

	return (
		<div className={styles.main}>
			<div className={styles.header}>
				<IconButton onClick={() => window.location.reload()}>
					<KeyboardBackspace />
				</IconButton>
				<div className={styles.head}>
					<h3>{type?.toUpperCase()}</h3>
				</div>
			</div>
			<div className={styles.datePicker}>
				<DatePicker
					color='var(--theme-color)'
					getSelectedDay={getSelectedDay}
				/>
			</div>
			<div className={styles.slotsDiv}>
				{loading ? (
					<div style={{ padding: "1rem" }}>
						<Skeleton
							style={{
								display: "inline-block",
								marginRight: "1rem",
							}}
							variant='rect'
							width={130}
							height={50}
						/>
						<Skeleton
							style={{
								display: "inline-block",
								marginRight: "1rem",
							}}
							variant='rect'
							width={130}
							height={50}
						/>
						<Skeleton
							style={{
								display: "inline-block",
							}}
							variant='rect'
							width={130}
							height={50}
						/>
					</div>
				) : (
					<div className={styles.box}>
						{slots?.length > 0 &&
						slots
							.map(
								(k) =>
									divideSlots(
										k.startTime,
										k.endTime,
										20,
										moment().format("YYYY-MM-DD") ===
											selectedDate
									).length > 0
							)
							.includes(true) ? (
							<>
								<h3>Available Slots</h3>
								<div className={styles.slots}>
									{slots?.map((k) =>
										divideSlots(
											k.startTime,
											k.endTime,
											20,
											moment().format("YYYY-MM-DD") ===
												selectedDate
										).map((slot, index) => (
											<Button
												key={index}
												onClick={() =>
													handleBookAppointment(
														slot,
														type
													)
												}
												style={{
													minWidth: "5vh",
													display: "inline-block",
													background: "#e9e9e9",
													padding: "0.6rem 1rem",
													whiteSpace: "nowrap",
													margin: "0 0.2rem",
												}}>
												{moment(
													slot?.startTime,
													"hh:mm:ss"
												).format("hh:mm a")}
											</Button>
										))
									)}
								</div>
							</>
						) : (
							<h3>No Slots Available</h3>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default AllSlots;
