import { toast } from "react-toastify";

const showNotification = (type, message) => {
	if (type === "error") {
		toast.error(message);
	} else if (type === "success") {
		toast.success(message);
	}
};

export default showNotification;
