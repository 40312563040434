import React, { useContext, createContext, useReducer } from "react";

export const AppContext = createContext();

export const ContextProvider = ({ reducer, initialState, children }) => (
	<AppContext.Provider value={useReducer(reducer, initialState)}>
		{children}
	</AppContext.Provider>
);

export const useAppContext = () => useContext(AppContext);
