import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
} from "@material-ui/core";

import { useAppContext } from "../context/StateProvider";
import { logout, resendOtp, sendOtp, verifyOtp } from "../actions/authActions";
import styles from "../styles/Navbar.module.css";

function Navbar() {
	const [open, setOpen] = useState(false);
	const [phone, setPhone] = useState("");
	const [otp, setOtp] = useState("");
	const [viewVerifyOtp, setViewVerifyOtp] = useState(false);

	const [{ isLoggedIn, otp_key }, dispatch] = useAppContext();

	const handleSendOtp = async () => {
		if (phone) {
			await sendOtp(phone, dispatch);
			setViewVerifyOtp(true);
			setPhone("");
		}
	};

	const handleVerifyOtp = async () => {
		if (otp) {
			await verifyOtp(otp_key, otp, dispatch);
			handleClose();
		}
	};

	const handleResendOtp = async () => {
		await resendOtp(otp_key);
	};

	const handleClose = () => {
		setOpen(false);
		setPhone("");
	};

	return (
		<nav className={styles.navbar}>
			<div className={styles.navContainer}>
				<div className={styles.navLogo}>
					<img
						style={{ width: "200px" }}
						src='/docplixlogowhite.png'
						alt='logo'
					/>
					{/* <span>Docplix</span> */}
				</div>
				<div className={styles.navButtons}>
					{!isLoggedIn ? (
						<Button
							varient='outlined'
							className={styles.navButton}
							onClick={() => setOpen(true)}>
							Login
						</Button>
					) : (
						<Button
							varient='outlined'
							className={styles.navButton}
							onClick={() => logout(dispatch)}>
							Logout
						</Button>
					)}
				</div>
				<Dialog
					aria-labelledby='customized-dialog-title'
					open={open}
					onClose={handleClose}
					fullWidth>
					<DialogTitle
						id='customized-dialog-title'
						onClose={handleClose}>
						Verify your number
					</DialogTitle>
					<DialogContent dividers>
						<DialogContentText>
							We will verify your number with an OTP
						</DialogContentText>
						{viewVerifyOtp ? (
							<TextField
								value={otp}
								onChange={(e) => setOtp(e.target.value)}
								autoFocus
								margin='dense'
								id='otp'
								label='Enter otp'
								type='number'
								fullWidth
								variant='standard'
							/>
						) : (
							<TextField
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								autoFocus
								margin='dense'
								id='phone'
								label='Enter phone number'
								type='number'
								fullWidth
								variant='standard'
							/>
						)}
					</DialogContent>
					<DialogActions>
						{viewVerifyOtp ? (
							<>
								<Button
									disabled={!otp}
									onClick={handleResendOtp}>
									Resend OTP
								</Button>
								<Button
									disabled={!otp}
									onClick={handleVerifyOtp}>
									Verify OTP
								</Button>
							</>
						) : (
							<Button disabled={!phone} onClick={handleSendOtp}>
								Continue
							</Button>
						)}
					</DialogActions>
				</Dialog>
			</div>
		</nav>
	);
}

export default Navbar;
