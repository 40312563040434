import axios from "axios";

import showNotification from "./showNotification";

const instance = axios.create({
	baseURL: process.env.REACT_APP_SERVER_URL,
});

instance.interceptors.response.use(
	function (res) {
		return res;
	},
	function (err) {
		console.log(err);
		showNotification(
			"error",
			err.response ? err.response.data.errors[0] : err.message
		);
		// alert(err);
		return Promise.reject(err);
	}
);

export default instance;
