import React from "react";

function Home() {
	return (
		<div>
			<h1>Appointment Booking Page</h1>
		</div>
	);
}

export default Home;
