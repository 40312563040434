import React, { useEffect, useState } from "react";
import moment from "moment";
import {
	Button,
	ButtonGroup,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from "@material-ui/core";
import { AccountCircle, KeyboardArrowRight } from "@material-ui/icons";

import { useAppContext } from "../context/StateProvider";
import styles from "../styles/Aside.module.css";
import { bookAppointment, getSlotsByDate } from "../actions/doctorActions";
import ConfirmPortal from "./ConfirmPortal";
import AllSlots from "./AllSlots";
import { Skeleton } from "@material-ui/lab";
import divideSlots from "../utils/divideSlots";

function Aside() {
	const [{ doctor, slots, user }, dispatch] = useAppContext();
	const [loading, setLoading] = useState(true);
	const [selectedOrg, setSelectedOrg] = useState(null);
	const [selectedSlot, setSelectedSlot] = useState(null);
	const [viewConfirmPortal, setViewConfirmPortal] = useState(false);
	const [showAllSlots, setShowAllSlots] = useState(false);
	const [selectedDate, setSelectedDate] = useState(
		moment().format("YYYY-MM-DD")
	);
	const [todaySlots, setTodaySlots] = useState([]);
	const [tab, setTab] = useState("inClinic");

	const handleChangeOrg = (e) => {
		setSelectedOrg(e.target.value);
	};

	const handleBookAppointment = (slot, appointmentType, confirm = false) => {
		if (confirm) {
			bookAppointment(
				{
					userId: user?.userId,
					vendorId: doctor?.Vendor_detail?.vendorId,
					date: moment(selectedDate).format("YYYY-MM-DD"),
					time: moment(slot?.startTime, "hh:mm:ss").format("hh:mm"),
					duration: moment(slot?.startTime, "hh:mm:ss")
						.add(20, "minutes")
						.format("hh:mm"),
					notes: "",
					appointmentType,
				},
				selectedOrg?.organisationId
			);
			setViewConfirmPortal(false);
			return;
		}
		setSelectedSlot(slot);
		setViewConfirmPortal(true);
	};

	// console.log(slots)

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			(async () => {
				if (doctor) {
					getSlotsByDate(
						tab,
						doctor?.id,
						moment().format("YYYY-MM-DD"),
						dispatch
					).then(() => setLoading(false));
					// getSlotsByDate(
					// 	{
					// 		vendorId: doctor?.id,
					// 		date: moment().format("YYYY-MM-DD"),
					// 	},
					// 	dispatch
					// ).then(() => setLoading(false));
				}
			})().then((allSlots) => {
				console.log({ allSlots });
				if (tab === "inClinic") {
					setTodaySlots(allSlots);
				}
			});
			if (doctor) {
				setSelectedOrg(doctor?.Vendor_organisations?.[0]);
			}
		}
		return () => {
			mounted = false;
			setLoading(true);
		};
	}, [tab, doctor, dispatch]);

	const renderLoadingComponent = () => {
		return (
			<>
				<Skeleton
					style={{
						display: "inline-block",
						marginRight: "1rem",
					}}
					variant='rect'
					width={130}
					height={50}
				/>
				<Skeleton
					style={{
						display: "inline-block",
						marginRight: "1rem",
					}}
					variant='rect'
					width={130}
					height={50}
				/>
				<Skeleton
					style={{
						display: "inline-block",
					}}
					variant='rect'
					width={130}
					height={50}
				/>
			</>
		);
	};

	return (
		<aside className={styles.aside}>
			<div className={styles.asideWrapper}>
				{viewConfirmPortal && (
					<ConfirmPortal
						selectedOrg={selectedOrg}
						type={tab}
						slot={selectedSlot}
						setConfirmPortal={setViewConfirmPortal}
						selectedDate={selectedDate}
						handleBookAppointment={handleBookAppointment}
					/>
				)}
				{showAllSlots && !viewConfirmPortal && (
					<AllSlots
						setSelectedDate={setSelectedDate}
						selectedDate={selectedDate}
						type={tab}
						handleBookAppointment={handleBookAppointment}
					/>
				)}
				{!viewConfirmPortal && !showAllSlots && (
					<>
						<h1>Book an appointment</h1>
						<ButtonGroup
							style={{ marginBottom: "1rem" }}
							fullWidth
							variant='contained'
							aria-label='outlined primary button group'>
							<Button
								onClick={() => setTab("inClinic")}
								style={{
									padding: "1rem",
									backgroundColor:
										tab === "inClinic"
											? "var(--theme-color)"
											: "white",
									color:
										tab === "inClinic" ? "white" : "black",
								}}
								startIcon={<AccountCircle />}>
								IN-CLINIC VISITS
							</Button>
							{/* <Button
								onClick={() => setTab("teleConsultation")}
								style={{
									padding: "1rem",
									backgroundColor:
										tab === "teleConsultation"
											? "var(--theme-color)"
											: "white",
									color:
										tab === "teleConsultation"
											? "white"
											: "black",
								}}
								startIcon={<Videocam />}>
								VIDEO CONSULTATION
							</Button> */}
						</ButtonGroup>
						{tab === "inClinic" && (
							<div className={styles.org}>
								<FormControl fullWidth>
									<InputLabel id='demo-simple-select-label'>
										Organisation
									</InputLabel>
									<Select
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										value={selectedOrg ?? ""}
										label='Org'
										onChange={handleChangeOrg}>
										{doctor?.Vendor_organisations?.map(
											(org, index) => (
												<MenuItem
													value={org}
													key={org?.organisationId}>
													<div
														className={
															styles.listItem
														}>
														<h3>
															{
																org
																	?.Organisation
																	?.name
															}
														</h3>
														<p>{`${
															org?.Organisation
																?.address1 || ""
														} ${
															org?.Organisation
																?.address2 || ""
														} ${
															org?.Organisation
																?.city || ""
														} ${
															org?.Organisation
																?.state || ""
														} ${
															org?.Organisation
																?.country || ""
														} - ${
															org?.Organisation
																?.pin || ""
														}`}</p>
													</div>
												</MenuItem>
											)
										)}
									</Select>
								</FormControl>
							</div>
						)}
						<div className={styles?.service}>
							<h3>Select service</h3>
							<div className={styles.container}>
								<div className={styles.cardHeader}>
									<div className={styles.headerLeft}>
										<AccountCircle />
										<p>Consultation</p>
									</div>
									<div className={styles.headerRight}>
										<p>
											<span className={styles.textLight}>
												from
											</span>{" "}
											<span className={styles.textBold}>
												Rs.
												{
													doctor?.Vendor_detail
														?.ticketingPrice
												}
											</span>
										</p>
									</div>
								</div>
								{slots?.length > 0 &&
								slots
									.map(
										(k) =>
											divideSlots(
												k.startTime,
												k.endTime,
												20,
												true
											).length > 0
									)
									.includes(true) ? (
									<p className={styles.helperText}>
										SLOTS AVAILABLE{" "}
										{moment().format("DD-MM-YY")}, TODAY
									</p>
								) : (
									<p className={styles.helperText}>
										NO SLOTS AVAILABLE FOR TODAY
									</p>
								)}

								<div
									style={{
										marginTop: "0.5rem",
										display: "grid",
										gridTemplateColumns: "repeat(3, 1fr)",
										whiteSpace: "nowrap",
									}}>
									{loading ? (
										renderLoadingComponent()
									) : tab === "inClinic" ? (
										<>
											{slots?.length > 0 &&
												slots.map((k) =>
													divideSlots(
														k.startTime,
														k.endTime,
														20,
														true
													)
														?.slice(0, 3)
														?.map((slot, index) => (
															<Button
																key={index}
																onClick={() =>
																	handleBookAppointment(
																		slot
																	)
																}
																style={{
																	minWidth:
																		"5vh",
																	display:
																		"inline-block",
																	background:
																		"#e9e9e9",
																	padding:
																		"0.6rem 1rem",
																	whiteSpace:
																		"nowrap",
																	margin: "0.2rem",
																}}>
																{moment(
																	slot?.startTime,
																	"hms s"
																).format(
																	"hh:mm a"
																)}
															</Button>
														))
												)}
										</>
									) : (
										slots?.length > 0 &&
										slots?.map((k) =>
											divideSlots(
												k.startTime,
												k.endTime,
												20
											)
												?.slice(0, 3)
												?.map((slot, i) => (
													<Button
														key={i}
														onClick={() =>
															handleBookAppointment(
																slot
															)
														}
														style={{
															minWidth: "5vh",
															display:
																"inline-block",
															background:
																"#e9e9e9",
															padding:
																"0.6rem 1rem",
															whiteSpace:
																"nowrap",
															margin: "0 0.2rem",
														}}>
														{moment(
															slot?.startTime,
															"hms s"
														).format("hh:mm a")}
													</Button>
												))
										)
									)}
								</div>
								<div>
									<Button
										endIcon={<KeyboardArrowRight />}
										onClick={() => setShowAllSlots(true)}
										style={{
											background: "var(--theme-color)",
											color: "white",
											marginTop: "1rem",
											padding: "0.8rem 0",
										}}
										fullWidth>
										See all slots
									</Button>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</aside>
	);
}

export default Aside;
