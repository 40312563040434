import {
	AUTH_LOGIN,
	AUTH_LOGOUT,
	SET_OTP_KEY,
	SET_USER,
} from "../context/types";
import axios from "../utils/axios";
import showNotification from "../utils/showNotification";

export const sendOtp = async (phone, dispatch) => {
	try {
		const res = await axios.post(`/user/getotp`, {
			phone,
			countryCode: "+91",
		});
		if (res.data.success) {
			dispatch({
				type: SET_OTP_KEY,
				payload: res.data.key,
			});
			console.log("OTP sent");
			showNotification("success", "OTP sent");
		}
	} catch (error) {
		dispatch({ type: SET_OTP_KEY, payload: null });
		console.error(error);
	}
};

export const resendOtp = async (key) => {
	try {
		const res = await axios.post(`/user/resend-otp`, { key });
		if (res.data.success) {
			console.log("OTP resent");
			showNotification("success", "OTP resent");
		}
	} catch (error) {
		console.error(error);
	}
};

export const verifyOtp = async (key, otp, dispatch) => {
	const res = await axios.post(`/user/verify-otp`, { key, otp });
	if (res.data.success) {
		setAuthorizationHeader(res.data.token);
		dispatch({
			type: AUTH_LOGIN,
		});
		dispatch({
			type: SET_USER,
			payload: res.data.user,
		});
		localStorage.setItem("authUser", JSON.stringify(res.data.user));
	}
};

export const logout = (dispatch) => {
	localStorage.removeItem("ehrToken");
	delete axios.defaults.headers.common.Authorization;
	dispatch({ type: AUTH_LOGOUT });
};

const setAuthorizationHeader = (token) => {
	localStorage.setItem("ehrToken", token);
	axios.defaults.headers.common.Authorization = token;
};
