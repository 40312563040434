import { Button, IconButton, TextField } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import { resendOtp, sendOtp, verifyOtp } from "../actions/authActions";
import { useAppContext } from "../context/StateProvider";

import styles from "../styles/ConfirmPortal.module.css";

function ConfirmPortal({
	type,
	selectedOrg,
	slot,
	setConfirmPortal,
	selectedDate,
	handleBookAppointment,
}) {
	const [{ doctor, otp_key, isLoggedIn }, dispatch] = useAppContext();
	const [viewVerifyOtp, setViewVerifyOtp] = useState(false);
	const [phone, setPhone] = useState("");
	const [otp, setOtp] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (phone) {
			await sendOtp(phone, dispatch);
			setViewVerifyOtp(true);
			setPhone("");
			setOtp("");
		}
		if (otp) {
			await verifyOtp(otp_key, otp, dispatch);
			setPhone("");
			setOtp("");
		}
	};

	return (
		<div className={styles.main}>
			<div className={styles.header}>
				<IconButton onClick={() => setConfirmPortal(false)}>
					<KeyboardBackspace />
				</IconButton>
				<div className={styles.head}>
					<h3>{type?.toUpperCase()}</h3>
				</div>
			</div>
			<div className={styles.box}>
				<div className={styles.innerBox}>
					<p>Date & Time</p>{" "}
					<p>
						{selectedDate +
							" | " +
							moment(slot?.startTime, "hms s").format("hh:mm a")}
					</p>
					<p>Doctor</p>
					<p>
						Dr.&nbsp;
						{doctor?.Vendor_detail?.name
							?.split(" ")?.[0]
							?.split("")?.[0]
							.toUpperCase() +
							doctor?.Vendor_detail?.name
								?.split(" ")?.[0]
								?.slice(1) +
							" " +
							doctor?.Vendor_detail?.name
								?.split(" ")?.[1]
								?.split("")?.[0]
								.toUpperCase() +
							doctor?.Vendor_detail?.name
								?.split(" ")?.[1]
								?.slice(1)}
					</p>
					{selectedOrg && type === "inClinic" && (
						<>
							<p>Organisation</p>
							<p>
								{selectedOrg?.Organisation?.name},{" "}
								{`${
									selectedOrg?.Organisation?.address1 || ""
								}, ${
									selectedOrg?.Organisation?.address2 || ""
								}, ${selectedOrg?.Organisation?.city || ""} ${
									selectedOrg?.Organisation?.state || ""
								}, ${
									selectedOrg?.Organisation?.country || ""
								} - ${selectedOrg?.Organisation?.pin || ""}`}
							</p>
						</>
					)}
				</div>
			</div>
			<div className={styles.verifyBox}>
				{isLoggedIn ? (
					<Button
						onClick={() => handleBookAppointment(slot, type, true)}
						fullWidth
						style={{
							background: "var(--theme-color)",
							color: "white",
							padding: "0.6rem 1rem",
							whiteSpace: "nowrap",
							margin: "0 0.2rem",
						}}>
						Confirm your booking
					</Button>
				) : (
					<>
						<h3>Verify your number</h3>
						<div className={styles.input}>
							<form
								onSubmit={(e) => e.preventDefault()}
								style={{
									display: "flex",
									alignItems: "flex-end",
								}}>
								{viewVerifyOtp ? (
									<>
										<div style={{ flex: 1 }}>
											<TextField
												value={otp}
												onChange={(e) =>
													setOtp(e.target.value)
												}
												autoFocus
												margin='dense'
												id='otp'
												label='Enter otp'
												type='number'
												fullWidth
												variant='standard'
											/>
											<p
												onClick={() =>
													resendOtp(otp_key)
												}
												style={{
													fontSize: "0.8rem",
													marginTop: "0.5rem",
													cursor: "pointer",
												}}>
												Resend OTP
											</p>
										</div>
										<div style={{ alignSelf: "center" }}>
											<Button
												style={{
													background:
														"var(--theme-color)",
													color: "white",
													padding: "0.3rem 0.5rem",
													whiteSpace: "nowrap",
													margin: "0 0.2rem",
												}}
												onClick={handleSubmit}>
												Verify OTP
											</Button>
										</div>
									</>
								) : (
									<>
										<div style={{ flex: 1 }}>
											<TextField
												value={phone}
												onChange={(e) =>
													setPhone(e.target.value)
												}
												autoFocus
												fullWidth
												margin='dense'
												id='phone'
												label='Enter phone number'
												type='number'
												variant='standard'
											/>
										</div>
										<div style={{ marginBottom: "0.4rem" }}>
											<Button
												style={{
													background:
														"var(--theme-color)",
													color: "white",
													padding: "0.3rem 0.5rem",
													whiteSpace: "nowrap",
													margin: "0 0.2rem",
												}}
												onClick={handleSubmit}>
												Send OTP
											</Button>
										</div>
									</>
								)}
							</form>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default ConfirmPortal;
