import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { useAppContext } from "../context/StateProvider";
import { AUTH_LOGIN, SET_USER } from "../context/types";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Home from "../Pages/Home/Home";
import axios from "../utils/axios";
import { logout } from "../actions/authActions";
import { ToastContainer } from "react-toastify";
import Navbar from "../components/Navbar";

function App() {
	const [{ isLoggedIn }, dispatch] = useAppContext();
	const token = localStorage.ehrToken;
	const authUser = localStorage.authUser;

	useEffect(() => {
		if (authUser) {
			dispatch({
				type: SET_USER,
				payload: JSON.parse(authUser),
			});
		}

		if (token) {
			dispatch({ type: AUTH_LOGIN });
			axios.defaults.headers.common.Authorization = token;
		} else if (!token && isLoggedIn) {
			logout(dispatch);
		}
	}, []);

	return (
		<Router>
			<ToastContainer
				limit={1}
				position='top-right'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={true}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				pauseOnHover
			/>
			<Navbar />
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/doctor/:doctorId' element={<Dashboard />} />
				<Route path='*' element={<>404</>} />
			</Routes>
		</Router>
	);
}

export default App;
