import React from "react";
import { Avatar, Button } from "@material-ui/core";
import {
	AccountCircle,
	Language,
	School,
	Videocam,
	LocalHospitalOutlined,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

import staticUrl from "../utils/staticUrl";
import { useAppContext } from "../context/StateProvider";
import styles from "../styles/DoctorProfile.module.css";

function DoctorProfile() {
	const [{ doctor }, dispatch] = useAppContext();

	if (!doctor) {
		return (
			<section className={styles.mainSection}>
				{/* header */}
				<header className={styles.header}>
					<div className={styles.logo}>
						<Skeleton variant='circle' width={200} height={200} />
					</div>
					<div className={styles.profile}>
						<p className={styles.regNo}>
							<Skeleton variant='text' />
						</p>
						<h1 className={styles.name}>
							<Skeleton variant='rect' width={300} height={50} />
						</h1>
						<p className={styles.speciality}>
							<Skeleton variant='text' />
						</p>
						<p className={styles.contact}>
							<Skeleton variant='rect' width={300} height={50} />
							<br />
							<Skeleton variant='rect' width={300} height={50} />
						</p>
					</div>
				</header>
				{/* experience */}
				<div className={styles.container}>
					<Skeleton variant='rect' width={500} height={300} />
				</div>
				{/* about */}
				<div className={styles.container}>
					<Skeleton variant='rect' width={500} height={300} />
				</div>
			</section>
		);
	}

	return (
		<section className={styles.mainSection}>
			{/* header */}
			<header className={styles.header}>
				<div className={styles.logo}>
					<Avatar
						src={`${staticUrl}/doctor-profile/${doctor?.Vendor_detail?.profileImage}`}
						alt='doctor profile'
					/>
				</div>
				<div className={styles.profile}>
					<p className={styles.regNo}>
						Registration No:&nbsp;
						{doctor?.Vendor_detail?.registrationNo}
					</p>
					<h1 className={styles.name}>
						Dr.&nbsp;
						{doctor?.Vendor_detail?.name
							?.split(" ")?.[0]
							?.split("")?.[0]
							.toUpperCase() +
							doctor?.Vendor_detail?.name
								?.split(" ")?.[0]
								?.slice(1) +
							" " +
							(doctor?.Vendor_detail?.name
								?.split(" ")?.[1]
								?.split("")?.[0]
								.toUpperCase() || "") +
							(doctor?.Vendor_detail?.name
								?.split(" ")?.[1]
								?.slice(1) || "")}
					</h1>
					<p className={styles.speciality}>
						{doctor?.Vendor_specialities?.[0]?.Speciality?.name}
					</p>
					<p className={styles.contact}>
						{doctor?.inClinic && (
							<Button
								style={{
									margin: "0.5rem",
									backgroundColor: "white",
								}}
								variant='outlined'
								startIcon={<AccountCircle />}>
								IN-CLINIC VISITS
							</Button>
						)}
						{/* {doctor?.teleConsultation && (
							<Button
								style={{
									backgroundColor: "white",
									margin: "0.5rem",
								}}
								variant='outlined'
								startIcon={<Videocam />}>
								VIDEO CONSULTATION
							</Button>
						)} */}
					</p>
				</div>
			</header>
			{/* experience */}
			<div className={styles.container}>
				<div className={styles.exp}>
					<School />
					<div className={styles.profile}>
						<h3 className={styles.smallHeading}>
							{doctor?.Vendor_detail?.yearOfExperience}&nbsp;yrs
						</h3>
						<p className={styles.lightText}>Overall Experience</p>
					</div>
				</div>
				{doctor?.Vendor_languages?.length > 0 && (
					<div className={styles.lang}>
						<Language />
						<div className={styles.profile}>
							<h3 className={styles.smallHeading}>
								{doctor?.Vendor_languages?.map(
									(lang) => lang?.Language?.name
								).join(", ")}
							</h3>
							<p className={styles.lightText}>Languages</p>
						</div>
					</div>
				)}
			</div>
			{/* about */}
			{doctor?.Vendor_detail?.description?.length > 0 && (
				<div className={styles.container}>
					<div>
						<h3 className={styles.smallHeading}>
							ABOUT THE DOCTOR
						</h3>
						<p
							className={styles.lightColor}
							style={{ marginTop: "0.8rem" }}>
							{doctor?.Vendor_detail?.description}
						</p>
					</div>
				</div>
			)}
			{/* specialization */}
			{doctor?.Vendor_specialities?.length > 0 && (
				<div
					className={styles.container}
					style={{ flexDirection: "column" }}>
					<h3 className={styles.smallHeading}>SPECIALIZATION</h3>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(2, 1fr)",
						}}>
						{doctor?.Vendor_specialities?.map((i, idx) => (
							<p
								key={idx}
								className={styles.list}
								style={{ marginTop: "0.8rem" }}>
								<LocalHospitalOutlined
									style={{ marginRight: "0.8rem" }}
								/>
								{i?.Speciality?.name}
							</p>
						))}
					</div>
				</div>
			)}
			{/* qualifications */}
			{doctor?.Qualifications?.length > 0 && (
				<div className={styles.container}>
					<div>
						<h3 className={styles.smallHeading}>
							EDUCATIONAL QUALIFICATIONS
						</h3>
						{doctor?.Qualifications?.map((i, idx) => (
							<div className={styles.fact} key={idx}>
								<h3 className={styles.smallHeading}>
									{i?.degreeName}{" "}
									{i?.completionYear?.length > 0 && "-"}
									{i?.completionYear || ""}
								</h3>
								<p className={styles.lightColor}>
									{i?.collegeName}
								</p>
							</div>
						))}
					</div>
				</div>
			)}
		</section>
	);
}

export default DoctorProfile;
