/* eslint-disable no-empty-pattern */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Aside from "../../components/Aside";
import DoctorProfile from "../../components/DoctorProfile";
import { useAppContext } from "../../context/StateProvider";
import { getDoctorById } from "../../actions/doctorActions";
import Navbar from "../../components/Navbar";
import styles from "../../styles/Dashboard.module.css";

function Dashboard() {
	const { doctorId } = useParams();

	const [{}, dispatch] = useAppContext();

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			const fetchOthers = async () => {
				await getDoctorById(doctorId, dispatch);
			};
			fetchOthers();
		}
		return () => {
			mounted = false;
		};
	}, [doctorId, dispatch]);

	return (
		<main>
			<section className={styles.body}>
				<div className={styles.profile}>
					<DoctorProfile />
				</div>
				<div className={styles.aside}>
					<Aside />
				</div>
			</section>
		</main>
	);
}

export default Dashboard;
