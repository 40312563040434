import moment from "moment";
import { SET_DOCTOR, SET_SLOTS } from "../context/types";
import axios from "../utils/axios";
import showNotification from "../utils/showNotification";

export const getDoctorById = async (id, dispatch) => {
	try {
		const res = await axios.get(`/docplix/doctors/${id.slice(3)}`);
		if (res.data.success) {
			dispatch({
				type: SET_DOCTOR,
				payload: res.data.doctor,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

// export const getSlotsByDate = async (params, dispatch) => {
// 	try {
// 		const res = await axios.get(`/doctor/appointment/slots`, { params });
// 		if (res.data.success) {
// 			dispatch({
// 				type: SET_SLOTS,
// 				payload: res.data.slots,
// 			});
// 			return res.data.slots || [];
// 		}
// 	} catch (error) {
// 		dispatch({
// 			type: SET_SLOTS,
// 			payload: [],
// 		});
// 		console.error(error);
// 	}
// };

export const getSlotsByDate = async (
	appointmentType,
	doctorId,
	date,
	dispatch
) => {
	try {
		// const date = new Date().toISOString();
		const res = await axios.get(`/docplix/slots`, {
			params: { appointmentType, doctorId, date },
		});
		if (res.data.success) {
			const sortedSlots =
				// appointmentType === "inClinic"
				// 	? res.data.slots[0]?.Slot_times.sort((a, b) =>
				// 			moment(b.startTime, "hh:mm:ss") <
				// 			moment(a.startTime, "hh:mm:ss")
				// 				? 1
				// 				: -1
				// 	  )
				// 	:
				res.data.slots?.sort((a, b) =>
					moment(b.startTime, "hh:mm:ss") <
					moment(a.startTime, "hh:mm:ss")
						? 1
						: -1
				);
			dispatch({
				type: SET_SLOTS,
				payload: sortedSlots || [],
			});
			return sortedSlots || [];
		} else {
			dispatch({
				type: SET_SLOTS,
				payload: [],
			});
		}
	} catch (error) {
		console.error(error);
		dispatch({
			type: SET_SLOTS,
			payload: [],
		});
	}
};

export const bookAppointment = async (appointment, organisationId) => {
	// appointment: userId, vendorId, date, time, duration, notes, appointmentType
	try {
		const res = await axios.post(`/docplix/appointment`, appointment, {
			headers: { organisationId },
		});
		if (res.data.success) {
			console.log(`Appointment Booked`);
			showNotification("success", "Appointment Booked");
		}
	} catch (error) {
		console.error(error);
	}
};
