const addMinutes = (time, minutes, today) => {
	var date = new Date(
		new Date("01/01/2015 " + time).getTime() + minutes * 60000
	);
	var tempTime =
		(date.getHours().toString().length === 1
			? "0" + date.getHours()
			: date.getHours()) +
		":" +
		(date.getMinutes().toString().length === 1
			? "0" + date.getMinutes()
			: date.getMinutes()) +
		":" +
		(date.getSeconds().toString().length === 1
			? "0" + date.getSeconds()
			: date.getSeconds());

	if (today) {
		const now = new Date();
		const k = time.split(":");
		if (now.getHours() > k[0]) {
			return { next: true, endTime: tempTime };
		} else if (now.getHours() === k[0] && now.getMinutes() > k[1]) {
			return { next: true, endTime: tempTime };
		}
	}
	return { startTime: time, endTime: tempTime };
};

const divideSlots = (startTime, endTime, duration = 20, today = false) => {
	if (!endTime) {
		return [
			{
				startTime,
				endTime,
			},
		];
	}
	let start = startTime;
	const end = endTime;
	const slots = [];

	while (start < end) {
		const data = addMinutes(start, duration, today);
		start = data.endTime;
		if (data.next) {
			continue;
		}
		slots.push({ startTime: data.startTime, endTime: data.endTime });
	}
	return slots;
};

export default divideSlots;
